import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';


const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src="/codelink.png" alt="Codelink Logo" className="logo-image" />
      </div>
      <nav>
        <ul>
          {[
            { name: "Home", path: "/" },
            { name: "Services", path: "/services" },
            { name: "Team", path: "/team" },
            { name: "Contact Us", path: "/contact" }
          ].map((item) => (
            <li key={item.name}>
              <Link to={item.path}>{item.name}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default Header;