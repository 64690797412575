import React from 'react';
import './Banner.css';
import Meteors from './Meteors';
import { Link } from 'react-router-dom';


const Banner: React.FC = () => {


  return (
    <div className="banner">
      <Meteors />
      <div className="mars-container"> {/* New container for mars and its text */}
        <Link to="/ui-ux-design" className="planet-text">UI/UX Design</Link>
        <div className="mars">
          <img src="/mars.png" alt="Rotating Planet" />
        </div>
      </div>
      <div className="mercury-container"> {/* New container for mars and its text */}
        <Link to="/web3-blockchain-services" className="planet-text">Web3 Blockchain</Link>
        <div className="mercury">
          <img src="/mercury.png" alt="Rotating Planet" />
        </div>
      </div>
      <div className="moon-container"> {/* New container for mars and its text */}
        <Link to="/fullstack-development" className="planet-text">Full-stack Development</Link>
        <div className="moon">
          <img src="/moon.png" alt="Rotating Planet" />
        </div>
      </div>
      <h1>Connecting Your Ideas with Global Coding Expertise!</h1>
      <p>Premium software outsourcing services linking you with top-tier developers worldwide.</p>

    </div>
    
  );
}

export default Banner;