import React from 'react';
import { Link } from 'react-router-dom';
import './Services.css';

const Services: React.FC = () => {
    const services = [
        {
            title: 'UI/UX Design',
            link: '/ui-ux-design',
            description: "At CODELINK SOFTWARE, we prioritize user-centric design principles, ensuring that every interface is intuitive, responsive, and tailored to the end-user's needs. Our design approach enhances user engagement and drives conversions.",
        },
        {
            title: 'Web3 & Blockchain',
            link: '/web3-blockchain-services',
            description: 'Navigating the decentralized web requires a unique skill set. Our team is adept at developing solutions powered by blockchain technology, ensuring security, transparency, and the true potential of decentralization in every product.',
        },
        {
            title: 'Full-stack Development',
            link: '/fullstack-development',
            description: 'With expertise in both frontend and backend technologies, our team provides comprehensive full-stack development services. We integrate cutting-edge technologies to deliver robust, scalable, and efficient applications tailored to your business needs.',
        },
    ];

    return (
        <div className="uiux-container">
            <div className="uiux-title">Our Services</div>
            {services.map((service, index) => (
                <div key={index} className="uiux-content">
                    <Link to={service.link} className="service-title">
                        {service.title}
                    </Link>
                    <p>{service.description}</p>
                </div>
            ))}
        </div>
    );
}

export default Services;
