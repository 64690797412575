import React from 'react';
import './Contact.css';

const Contact: React.FC = () => {
  return (
    <div className="contact-container">
      <h1 className="contact-title">Contact Us</h1>
      <p className="contact-content">Have questions or interested in our services? Reach out to us!</p>

      <section className="contact-details">
        <h2>Get In Touch</h2>
        <p className="contact-content">Fill in the form below and our team will get back to you as soon as possible.</p>

        <form className="contact-form">
            <div className="input-group">
                <label>Name:</label>
                <input type="text" placeholder="Enter your name" />
            </div>
            <div className="input-group">
                <label>Email:</label>
                <input type="email" placeholder="Enter your email" />
            </div>
            <div className="input-group">
                <label>Message:</label>
                <textarea placeholder="Your message..."></textarea>
            </div>
            <div className="submit-btn">
                <button type="submit">Submit</button>
            </div>
        </form>
      </section>
    </div>
  );
}

export default Contact;
