import React from 'react';
import './UIUXDesignPage.css';

const Team: React.FC = () => {
    return (
        <div className="uiux-container">
            <h1 className="uiux-title">Our Team</h1>
            <p className="uiux-content">
                Located at the heart of technological innovation, Silicon Valley, our team embodies the spirit of forward-thinking and cutting-edge technology. We take immense pride in our diverse team that brings together some of the brightest minds from around the world.
            </p>
            
            <p className="uiux-content">
                Our members have honed their skills and have been refined by their experiences from working with tech giants like Google, Apple, and Cisco. This collective experience has ingrained in us a profound understanding of user-centric designs, robust engineering solutions, and the intricacies of product development.
            </p>

            <p className="uiux-content">
                Whether it's the visionary thinking of our designers or the problem-solving acumen of our engineers, our team is driven by a shared passion for excellence. We are more than just professionals; we are pioneers striving to reshape the digital landscape. Collaboration, innovation, and dedication are the cornerstones of our team's ethos.
            </p>

            <p className="uiux-content">
                Join us on our journey as we continue to push boundaries, deliver exceptional solutions, and redefine what's possible in the tech world.
            </p>
        </div>
    );
}

export default Team;
