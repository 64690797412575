import React, { useEffect, useRef } from 'react';
import './Meteors.css';

const Meteors: React.FC = () => {
    const meteorsCount = 30; // 10 meteors for each path
    const meteorRefs = Array.from({ length: meteorsCount }).map(() => React.createRef<HTMLDivElement>());
    const marsCoordinates = { x: '15%', y: '45%' };
    const mercuryCoordinates = { x: '40%', y: '10%' };
    const moonCoordinates = { x: '75%', y: '40%' };


    useEffect(() => {
        meteorRefs.forEach((ref, index) => {
            const meteorInterval = setInterval(() => {
                const meteor = ref.current;
    
                if (meteor) {
                    let startX, startY, endX, endY;
    
                    const marsPosition = {
                        left: window.innerWidth * 0.15,
                        top: window.innerHeight * 0.55
                    };
                    const mercuryPosition = {
                        left: window.innerWidth * 0.40,
                        top: window.innerHeight * 0.30
                    };
                    const moonPosition = {
                        left: window.innerWidth * 0.75,
                        top: window.innerHeight * 0.50
                    };
    
                    if (index < meteorsCount / 3) { 
                        startX = moonPosition.left;
                        startY = moonPosition.top;
                        endX = marsPosition.left;
                        endY = marsPosition.top;
                    } else if (index < 2 * meteorsCount / 3) {
                        startX = marsPosition.left;
                        startY = marsPosition.top;
                        endX = mercuryPosition.left;
                        endY = mercuryPosition.top;
                    } else { 
                        startX = mercuryPosition.left;
                        startY = mercuryPosition.top;
                        endX = moonPosition.left;
                        endY = moonPosition.top;
                    }
    
                    const size = Math.random() * 8 + 5; // random size
                    meteor.style.width = `${size}px`;
                    meteor.style.height = `${size}px`;
    
                    meteor.style.left = `${startX}px`;
                    meteor.style.top = `${startY}px`;
                    meteor.style.opacity = '1';
    
                    const duration = Math.random() * 1000 + 1500; 
    
                    const animation = meteor.animate([
                        { transform: `translate(0, 0)` },
                        { transform: `translate(${endX - startX}px, ${endY - startY}px)` }
                    ], {
                        duration: duration,
                        iterations: 1,
                        easing: 'linear'
                    });
    
                    animation.onfinish = () => {
                        meteor.style.opacity = '0';
                    };
                }
            }, Math.random() * 5000 + 2000);
    
            return () => clearInterval(meteorInterval);
        });
    }, []);
    

    return (
        <div className="meteor-container">
            {meteorRefs.map((ref, index) => (
                <div key={index} className="meteor" ref={ref}></div>
            ))}
        </div>
    );
}

export default Meteors;
