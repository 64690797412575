import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Banner from './components/Banner';
import UIUXDesignPage from './components/UIUXDesignPage';
import Web3BlockchainServices from './components/Web3BlockchainServices';
import FullStackDevelopment from './components/FullStackDevelopment';
import Contact from './components/Contact';
import Services from './components/Services';
import Team from './components/Team';
// ... Import other components

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Banner />} />
          <Route path="/ui-ux-design" element={<UIUXDesignPage />} />
          <Route path="/web3-blockchain-services" element={<Web3BlockchainServices />} />
          <Route path="/fullstack-development" element={<FullStackDevelopment />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/team" element={<Team />} />
          {/* ... Other routes/components */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;