import React from 'react';
import './UIUXDesignPage.css';
import { Link } from 'react-router-dom';

const UIUXDesignPage: React.FC = () => {
    return (
        <div className="uiux-container">
            <div className="uiux-wrapper">
            <h1 className="uiux-title">UI/UX Design Services</h1>
            <p className="uiux-content">
                At CODELINK SOFTWARE, we provide top-notch UI/UX design services that 
                enhance user experience, drive user engagement, and boost conversions.
                We provide:
            </p>
            <ul className="uiux-list">
                <li>UI Design & Wireframing</li>
                <li>User Experience Analysis</li>
                <li>Interactive Prototypes</li>
                <li>Usability Testing</li>
                <li>Responsive Design</li>
            </ul>
            </div>
            <h3 className="ai-section-title">How we provide UI/UX designs that both you and your customers will love:</h3>
            <div className="ai-section">
                <div className="ai-step">
                    <div className="ai-step-icon">1</div>
                    <h2>User Research:</h2>
                    <p>Competitor Analysis: Examine competitors' products to understand industry standards and identify areas for improvement.User Surveys and Interviews: Gather data directly from users to understand their needs and preferences.Personas Creation: Develop user personas to guide design decisions based on user needs.</p>
                </div>
                
                <div className="ai-step">
                    <div className="ai-step-icon">2</div>
                    <h2>Interface Design:</h2>
                    <p>Mood Boards and Style Guides: Develop mood boards and style guides to ensure consistency in design elements such as colors, fonts, and imagery.
Mockups: Create detailed mockups of your product's user interface, providing a visual representation of the final design.
Prototyping: Develop interactive prototypes to give you a feel of how the final product will work.</p>
                </div>

                <div className="ai-step">
                    <div className="ai-step-icon">3</div>
                    <h2>Usability Testing:</h2>
                    <p>User Testing: Conduct usability testing sessions with real users to gather feedback on your product's design and functionality.
A/B Testing: Implement A/B testing to compare different design variations and identify which one performs better.
Analytics Review: Analyze user data and metrics to understand how users interact with your product and where improvements can be made.</p>
                </div>

                <div className="ai-step">
                    <div className="ai-step-icon">4</div>
                    <h2>Support and Maintenance:</h2>
                    <p>Design Updates: Provide ongoing support for design updates and enhancements.
User Feedback Monitoring: Continuously monitor user feedback and make necessary design adjustments to improve user satisfaction.
</p>
                </div>
            </div>
            <div className="cta">
                <Link to="/contact" className="contact-link">Get Started with our Full-stack Services</Link>
            </div>
        </div>
    );
}

export default UIUXDesignPage;
