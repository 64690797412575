import React from 'react';
import './UIUXDesignPage.css';
import { Link } from 'react-router-dom';

const FullStackDevelopment: React.FC = () => {
    return (
        <div className="uiux-container">
            <div className="uiux-wrapper">
            <h1 className="uiux-title">Full-stack Development Services</h1>
            <p className="uiux-content">
            Our team of experienced full-stack developers utilizes the latest technologies and best practices to deliver high-quality, performant, and secure applications tailored to your requirements.
            We have the experience required to help you build and release high-quality products to the market that result in high impact for your users.
            Here's what we can help:
            </p>
            <ul className="uiux-list">
                <li>Responsive Web Design & Development</li>
                <li>API Design & Integration</li>
                <li>Database Design & Optimization</li>
                <li>Deployment, Scaling & Performance Monitoring</li>
            </ul>
            </div>
            <h3 className="ai-section-title">How we build products that succeed：</h3>
            <div className="ai-section">
                <div className="ai-step">
                    <div className="ai-step-icon">1</div>
                    <h2>MVP & V1 Release：</h2>
                    <p>We create high-impact minimum viable product (MVP) and version 1 releases, enabling you to enter the market quickly.</p>
                </div>
                
                <div className="ai-step">
                    <div className="ai-step-icon">2</div>
                    <h2>Iterating in Budget：</h2>
                    <p>We work iteratively within your budget, releasing features rapidly and focusing on the ones that matter most.</p>
                </div>

                <div className="ai-step">
                    <div className="ai-step-icon">3</div>
                    <h2>End-to-End Support：</h2>
                    <p>We’re here for you throughout all stages of the software development lifecycle.</p>
                </div>

                <div className="ai-step">
                    <div className="ai-step-icon">4</div>
                    <h2>Ownership：</h2>
                    <p>Our members embody our core value of ownership and treat your product as if it were their own, raising suggestions and collaborating directly with you.</p>
                </div>
                <div className="ai-step">
                    <div className="ai-step-icon">5</div>
                    <h2>Stable Teams：</h2>
                    <p>With over 85% retention, team members love where they work, which ensures that they are driven and motivated when joining your project.</p>
                </div>
                <div className="ai-step">
                    <div className="ai-step-icon">6</div>
                    <h2>Vendor Collaboration：</h2>
                    <p>We are open to a range of collaboration methods and happy to work with your team or other vendor teams.</p>
                </div>
            </div>
            <div className="cta">
                <Link to="/contact" className="contact-link">Get Started with our Full-stack Services</Link>
            </div>
        </div>
    );
}

export default FullStackDevelopment;
