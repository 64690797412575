import React from 'react';
import './UIUXDesignPage.css';
import { Link } from 'react-router-dom';

const Web3BlockchainServices: React.FC = () => {
    return (
        <div className="uiux-container">
            <div className="uiux-wrapper">
            <h1 className="uiux-title">Web3 & Blockchain Services</h1>
            <p className="uiux-content">
            With a team of blockchain experts having extensive experience in Solidity, Ethereum, Binance Smart Chain, and more, we provide top-tier Web3 solutions tailored for your needs.
            We offer:
            </p>
            <ul className="uiux-list">
                <li>Smart Contract Development & Audit</li>
                <li>Decentralized Application (DApp) Development</li>
                <li>Token Development & ICO/IDO Launches</li>
                <li>Blockchain Integration & Consulting</li>
            </ul>
            </div>
            <h3 className="ai-section-title">How we build products that succeed：</h3>
            <div className="ai-section">
                <div className="ai-step">
                    <div className="ai-step-icon">1</div>
                    <h2>Understanding the Decentralized Landscape:</h2>
                    <p>We begin by diving deep into the decentralized ethos, ensuring that our approach aligns with the principles of transparency, autonomy, and trust.
We familiarize ourselves with the foundational technologies that make up web3, including blockchain, decentralized file storage systems, and peer-to-peer networks.</p>
                </div>
                
                <div className="ai-step">
                    <div className="ai-step-icon">2</div>
                    <h2>User-Centric Design:</h2>
                    <p>In the world of web3, user experience can be challenging due to concepts like gas fees, wallet addresses, and more. We prioritize creating intuitive user interfaces that simplify these complexities.
We focus on seamless onboarding experiences, ensuring users can easily navigate and understand the decentralized applications (dApps).</p>
                </div>

                <div className="ai-step">
                    <div className="ai-step-icon">3</div>
                    <h2>Smart Contract Development:</h2>
                    <p>Our team of experts drafts and reviews smart contracts with utmost precision to ensure security and functionality.
We utilize tools like Truffle, Hardhat, and Remix for development and testing, ensuring our contracts are free from vulnerabilities.</p>
                </div>

                <div className="ai-step">
                    <div className="ai-step-icon">4</div>
                    <h2>Security & Audits:</h2>
                    <p>The decentralized world demands impeccable security. We conduct rigorous audits using both automated tools and expert reviewers.
Regular updates and patches are implemented to stay ahead of potential threats.</p>
                </div>
                <div className="ai-step">
                    <div className="ai-step-icon">5</div>
                    <h2>Community Engagement:</h2>
                    <p>Web3 and blockchain thrive on community. We focus on building and nurturing a strong community around our products.
Engaging in regular feedback sessions, AMAs (Ask Me Anything), and community-driven initiatives.</p>
                </div>
                <div className="ai-step">
                    <div className="ai-step-icon">6</div>
                    <h2>Scalability & Efficiency:</h2>
                    <p>As our products grow, we ensure they remain efficient and scalable. We utilize layer 2 solutions, sidechains, and other mechanisms to handle increased traffic and reduce costs.</p>
                </div>
            </div>
            <div className="cta">
                <Link to="/contact" className="contact-link">Get Started with our Full-stack Services</Link>
            </div>
        </div>
    );
}

export default Web3BlockchainServices;

